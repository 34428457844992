import React, { useState } from 'react';
import Footer from './footer';
import { Link, useNavigate } from 'react-router-dom';

function Contact() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate(); // useNavigate hook from react-router-dom

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log('Form submitted');
    setLoading(true);
    setError('');
    setSuccess(false);

    const form = event.target;
    const formData = new FormData(form);

    try {
      const response = await fetch('http://localhost:3001/send-email', {
        method: 'POST',
        body: formData,
      });

      const result = await response.json();
      console.log('Response received:', result);

      if (response.ok) {
        setSuccess(true);
        form.reset();
        alert("Your Response Has Been Recorded. We will get back to you soon....");
      } else {
        setError(result.error);
      }
    } catch (error) {
      setError('Error submitting form');
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  const closeSuccessPopup = () => {
    setSuccess(false);
  };

  return (
    <>
      <main id="main">
        <div className="breadcrumbs d-flex align-items-center" style={{ backgroundImage: "url('assets/img/breadcrumbs-bg.jpg')" }}>
          <div className="container position-relative d-flex flex-column align-items-center" data-aos="fade">
            <h2>Contact</h2>
            <ol>
              <li><Link to="/">Home</Link></li>
              <li>Contact</li>
            </ol>
          </div>
        </div>

        <section id="contact" className="contact">
          <div className="container" data-aos="fade-up" data-aos-delay="100">
            <div className="row gy-4">
              <div className="col-lg-6">
                <div className="info-item d-flex flex-column justify-content-center align-items-center">
                  <i className="bi bi-map"></i>
                  <h3>Our Address</h3>
                  <p>Odessa, Florida</p>
                </div>
              </div>

              <div className="col-lg-3 col-md-6">
                <div className="info-item d-flex flex-column justify-content-center align-items-center">
                  <i className="bi bi-envelope"></i>
                  <h3>Email Us</h3>
                  <p>info@kryssolutions.com</p>
                </div>
              </div>

              <div className="col-lg-3 col-md-6">
                <div className="info-item d-flex flex-column justify-content-center align-items-center">
                  <i className="bi bi-telephone"></i>
                  <h3>Call Us</h3>
                  <p>+1 656-217-1792</p>
                </div>
              </div>
            </div>

            <div className="row gy-4 mt-1">
              <div className="col-lg-6">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d41818930.35112194!2d-123.23237808038162!3d50.22796560381668!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88c295ba866ddaf1%3A0xe737c74580151604!2sOdessa%2C%20FL%2C%20USA!5e0!3m2!1sen!2sin!4v1714462530743!5m2!1sen!2sin" width="100%" height="384" style={{ border: '0' }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
              </div>

              <div className="col-lg-6">
                <form id="contactForm" onSubmit={handleSubmit} encType="multipart/form-data" className="php-email-form">
                  <div className="row gy-4">
                    <div className="col-lg-6 form-group">
                      <input type="text" name="name" className="form-control" id="name" placeholder="Your Name" required />
                    </div>
                    <div className="col-lg-6 form-group">
                      <input type="email" className="form-control" name="email" id="email" placeholder="Your Email" required />
                    </div>
                  </div>
                  <div className="form-group">
                    <input type="text" className="form-control" name="subject" id="subject" placeholder="Subject" required />
                  </div>
                  <div className="form-group">
                    <textarea className="form-control" name="message" rows="5" placeholder="Message" required></textarea>
                  </div>
                  <div className="form-group">
                    <label htmlFor="fileUpload" className="form-label" id="fileUpload">Upload your Resume</label>
                    <input className="form-control form-control-lg" id="fileUpload" name="fileUpload" type="file" />
                  </div>
                  
                  <div className="text-center">
                    <button type="submit" disabled={loading}>Send Message</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />

      {success && (
        <div id="successPopup" className="success-container">
          <div className="success-message">
            <div className="msg-body">
              <div className="main-msg">
                <div className="msg-border">
                  <div className="msg-tick">
                    <span style={{ fontSize: '71px', color: '#87cc6d' }}>&#10004;</span>
                  </div>
                </div>
              </div>
              <div className="successful-msg">Successfully Submitted</div>
              <div className="msg-copy">
                <div className="msg-link">We will get back to you soon!</div>
              </div>
              <div className="main_button">
                <button className="button_ok" onClick={closeSuccessPopup}>OK</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Contact;













// try {
//   const response = await emailjs.sendForm(
//     'service_meutfyn',
//     'template_x5yoto2',
//     e.target,
//     'W4ZqxpvtNzfv00kOv' // replace with your actual public key
//   );






// import React, { useState } from 'react';
// import Footer from './footer';
// import { Link } from 'react-router-dom';
// import emailjs from 'emailjs-com';

// function Contact() {
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState('');
//   const [success, setSuccess] = useState(false);

//   const [formData, setFormData] = useState({
//     name: '',
//     email: '',
//     subject: '',
//     message: '',
//     resume: null, // New state for resume file
//   });

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//   };

//   const handleFileChange = (e) => {
//     const file = e.target.files[0];
//     const maxSize = 5 * 1024 * 1024; // 5 MB limit
//     if (file.size > maxSize) {
//       setError('File size exceeds the 5MB limit.');
//       e.target.value = null; // Clear the file input
//     } else {
//       setFormData({
//         ...formData,
//         resume: file,
//       });
//       setError('');
//     }
//   };

//   const sendEmail = async (e) => {
//     e.preventDefault();
//     console.log('Form submitted');
//     setLoading(true);
//     setError('');
//     setSuccess(false);

//     const { name, email, subject, message, resume } = formData;

//     try {
//       const templateParams = {
//         from_name: name,
//         from_email: email,
//         to_email: 'info@kryssolutions.com', // Replace with your recipient email address
//         subject: subject,
//         message_html: message,
//       };

//       // Send email using EmailJS
//       const response = await emailjs.send(
//         'service_meutfyn', // Replace with your EmailJS service ID
//         'template_x5yoto2', // Replace with your EmailJS template ID
//         templateParams,
//         'W4ZqxpvtNzfv00kOv' // Replace with your EmailJS user ID (optional)
//       );

//       console.log('Email sent:', response);

//       if (response.status === 200) {
//         setSuccess(true);
//         setLoading(false);
//         setFormData({
//           name: '',
//           email: '',
//           subject: '',
//           message: '',
//           resume: null,
//         });
//         alert("Your Response Has Been Recorded. We will get back to you soon....");
//       } else {
//         setError('Failed to send email. Please try again later.');
//         setLoading(false);
//       }
//     } catch (error) {
//       setError('Error sending email. Please try again later.');
//       console.error('Error:', error);
//       setLoading(false);
//     }
//   };

//   const closeSuccessPopup = () => {
//     setSuccess(false);
//   };

//   return (
//     <>
//       <main id="main">
//         <div className="breadcrumbs d-flex align-items-center" style={{ backgroundImage: "url('assets/img/breadcrumbs-bg.jpg')" }}>
//           <div className="container position-relative d-flex flex-column align-items-center" data-aos="fade">
//             <h2>Contact</h2>
//             <ol>
//               <li><Link to="/">Home</Link></li>
//               <li>Contact</li>
//             </ol>
//           </div>
//         </div>

//         <section id="contact" className="contact">
//           <div className="container" data-aos="fade-up" data-aos-delay="100">
//             <div className="row gy-4">
//               <div className="col-lg-6">
//                 <div className="info-item d-flex flex-column justify-content-center align-items-center">
//                   <i className="bi bi-map"></i>
//                   <h3>Our Address</h3>
//                   <p>Odessa, Florida</p>
//                 </div>
//               </div>

//               <div className="col-lg-3 col-md-6">
//                 <div className="info-item d-flex flex-column justify-content-center align-items-center">
//                   <i className="bi bi-envelope"></i>
//                   <h3>Email Us</h3>
//                   <p>info@kryssolutions.com</p>
//                 </div>
//               </div>

//               <div className="col-lg-3 col-md-6">
//                 <div className="info-item d-flex flex-column justify-content-center align-items-center">
//                   <i className="bi bi-telephone"></i>
//                   <h3>Call Us</h3>
//                   <p>+1 656-217-1792</p>
//                 </div>
//               </div>
//             </div>

//             <div className="row gy-4 mt-1">
//               <div className="col-lg-6">
//                 {/* Google Maps Embed */}
//                 <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d41818930.35112194!2d-123.23237808038162!3d50.22796560381668!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88c295ba866ddaf1%3A0xe737c74580151604!2sOdessa%2C%20FL%2C%20USA!5e0!3m2!1sen!2sin!4v1714462530743!5m2!1sen!2sin" width="100%" height="384" style={{ border: '0' }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
//               </div>

//               <div className="col-lg-6">
//                 {/* Contact Form */}
//                 <form id="contactForm" onSubmit={sendEmail} encType="multipart/form-data" className="php-email-form">
//                   <div className="row gy-4">
//                     <div className="col-lg-6 form-group">
//                       <input type="text" name="name" className="form-control" id="name" placeholder="Your Name" value={formData.name} onChange={handleChange} required />
//                     </div>
//                     <div className="col-lg-6 form-group">
//                       <input type="email" className="form-control" name="email" id="email" placeholder="Your Email" value={formData.email} onChange={handleChange} required />
//                     </div>
//                   </div>
//                   <div className="form-group">
//                     <input type="text" className="form-control" name="subject" id="subject" placeholder="Subject" value={formData.subject} onChange={handleChange} required />
//                   </div>
//                   <div className="form-group">
//                     <textarea className="form-control" name="message" rows="5" placeholder="Message" value={formData.message} onChange={handleChange} required></textarea>
//                   </div>
//                   <div className="form-group">
//                     <label htmlFor="resume" className="form-label">Upload your Resume (max 5MB)</label>
//                     <input className="form-control form-control-lg" id="resume" name="resume" type="file" onChange={handleFileChange} />
//                     {error && <small className="text-danger">{error}</small>}
//                   </div>
                  
//                   <div className="text-center">
//                     <button type="submit" disabled={loading}>Send Message</button>
//                   </div>
//                 </form>
//               </div>
//             </div>
//           </div>
//         </section>
//       </main>
//       {/* Success Popup */}
//       {success && (
//         <div id="successPopup" className="success-container">
//           <div className="success-message">
//             <div className="msg-body">
//               <div className="main-msg">
//                 <div className="msg-border">
//                   <div className="msg-tick">
//                     <span style={{ fontSize: '71px', color: '#87cc6d' }}>&#10004;</span>
//                   </div>
//                 </div>
//               </div>
//               <div className="successful-msg">Successfully Submitted</div>
//               <div className="msg-copy">
//                 <div className="msg-link">We will get back to you soon!</div>
//               </div>
//               <div className="main_button">
//                 <button className="button_ok" onClick={closeSuccessPopup}>OK</button>
//               </div>
//             </div>
//           </div>
//         </div>
//       )}
//       <Footer />
//     </>
//   );
// }

// export default Contact;











// import React, { useState } from 'react';
// import emailjs from 'emailjs-com';

// const ContactForm = () => {
//   const [name, setName] = useState('');
//   const [email, setEmail] = useState('');
//   const [message, setMessage] = useState('');
//   const [resume, setResume] = useState(null);
//   const [successMessage, setSuccessMessage] = useState('');
//   const [errorMessage, setErrorMessage] = useState('');

//   const handleFileChange = (e) => {
//     const file = e.target.files[0];
//     setResume(file);
//   };

//   const sendEmailWithAttachment = async () => {
//     try {
//       // Prepare template params
//       const templateParams = {
//         to_name: 'Recipient Name', // Replace with recipient's name
//         from_name: name,
//         subject: 'New Message from Contact Form',
//         name,
//         email,
//         message,
//       };

//       // Create FormData object
//       const formData = new FormData();
//       formData.append('to_name', 'Recipient Name'); // Replace with recipient's name
//       formData.append('from_name', name);
//       formData.append('subject', 'New Message from Contact Form');
//       formData.append('name', name);
//       formData.append('email', email);
//       formData.append('message', message);
//       if (resume) {
//         formData.append('resume.pdf', resume, resume.name);
//       }

//       // Send email using EmailJS
//       const response = await emailjs.send('service_meutfyn', 'template_x5yoto2', templateParams, 'W4ZqxpvtNzfv00kOv', formData);
//       console.log('Email sent:', response);

//       // Reset form and show success message
//       setName('');
//       setEmail('');
//       setMessage('');
//       setResume(null);
//       setSuccessMessage('Your message has been sent successfully!');
//       setErrorMessage('');
//     } catch (error) {
//       console.error('Error sending email:', error);
//       setSuccessMessage('');
//       setErrorMessage('Failed to send the email. Please try again later.');
//     }
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     sendEmailWithAttachment();
//   };

//   return (
//     <div>
//       <h2>Contact Us</h2>
//       <form onSubmit={handleSubmit}>
//         <div>
//           <label>Name:</label>
//           <input type="text" value={name} onChange={(e) => setName(e.target.value)} required />
//         </div>
//         <div>
//           <label>Email:</label>
//           <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
//         </div>
//         <div>
//           <label>Message:</label>
//           <textarea value={message} onChange={(e) => setMessage(e.target.value)} required />
//         </div>
//         <div>
//           <label>Resume:</label>
//           <input type="file" onChange={handleFileChange} accept=".pdf,.doc,.docx" />
//         </div>
//         <button type="submit">Send Message</button>
//       </form>
//       {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
//       {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
//     </div>
//   );
// };

// export default ContactForm;









// import React, { useState } from 'react';
// import Footer from './footer';
// import { Link } from 'react-router-dom';
// import emailjs from 'emailjs-com';

// function Contact() {
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState('');
//   const [success, setSuccess] = useState(false);

//   const [formData, setFormData] = useState({
//     name: '',
//     email: '',
//     subject: '',
//     message: '',
//     resume: null,
//   });

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//   };

//   const handleFileChange = (e) => {
//     const file = e.target.files[0];
//     const maxSize = 5 * 1024 * 1024; // 5 MB limit
//     if (file.size > maxSize) {
//       setError('File size exceeds the 5MB limit.');
//       e.target.value = null; // Clear the file input
//     } else {
//       setFormData({
//         ...formData,
//         resume: file,
//       });
//       setError('');
//     }
//   };

//   const sendEmail = async (e) => {
//     e.preventDefault();
//     console.log('Form submitted');
//     setLoading(true);
//     setError('');
//     setSuccess(false);

//     const { name, email, subject, message, resume } = formData;

//     try {
//       const templateParams = {
//         from_name: name,
//         from_email: email,
//         to_email: 'info@kryssolutions.com', // Replace with your recipient email address
//         subject: subject,
//         message_html: message,
//       };

//       // Create a new FormData object for emailjs sendForm method
//       const formDataWithFile = new FormData();
//       formDataWithFile.append('from_name', name);
//       formDataWithFile.append('from_email', email);
//       formDataWithFile.append('to_email', 'info@kryssolutions.com'); // Replace with your recipient email address
//       formDataWithFile.append('subject', subject);
//       formDataWithFile.append('message_html', message);
//       formDataWithFile.append('resume', resume);

//       // Send email using EmailJS sendForm method
//       const response = await emailjs.sendForm(
//         'service_meutfyn', // Replace with your EmailJS service ID
//         'template_x5yoto2', // Replace with your EmailJS template ID
//         formDataWithFile,
//         'W4ZqxpvtNzfv00kOv' // Replace with your EmailJS user ID (optional)
//       );

//       console.log('Email sent:', response);

//       if (response.status === 200) {
//         setSuccess(true);
//         setLoading(false);
//         setFormData({
//           name: '',
//           email: '',
//           subject: '',
//           message: '',
//           resume: null,
//         });
//         alert("Your Response Has Been Recorded. We will get back to you soon....");
//       } else {
//         setError('Failed to send email. Please try again later.');
//         setLoading(false);
//       }
//     } catch (error) {
//       setError('Error sending email. Please try again later.');
//       console.error('Error:', error);
//       setLoading(false);
//     }
//   };

//   const closeSuccessPopup = () => {
//     setSuccess(false);
//   };

//   return (
//     <>
//       <main id="main">
//         <div className="breadcrumbs d-flex align-items-center" style={{ backgroundImage: "url('assets/img/breadcrumbs-bg.jpg')" }}>
//           <div className="container position-relative d-flex flex-column align-items-center" data-aos="fade">
//             <h2>Contact</h2>
//             <ol>
//               <li><Link to="/">Home</Link></li>
//               <li>Contact</li>
//             </ol>
//           </div>
//         </div>

//         <section id="contact" className="contact">
//           <div className="container" data-aos="fade-up" data-aos-delay="100">
//             <div className="row gy-4">
//               <div className="col-lg-6">
//                 <div className="info-item d-flex flex-column justify-content-center align-items-center">
//                   <i className="bi bi-map"></i>
//                   <h3>Our Address</h3>
//                   <p>Odessa, Florida</p>
//                 </div>
//               </div>

//               <div className="col-lg-3 col-md-6">
//                 <div className="info-item d-flex flex-column justify-content-center align-items-center">
//                   <i className="bi bi-envelope"></i>
//                   <h3>Email Us</h3>
//                   <p>info@kryssolutions.com</p>
//                 </div>
//               </div>

//               <div className="col-lg-3 col-md-6">
//                 <div className="info-item d-flex flex-column justify-content-center align-items-center">
//                   <i className="bi bi-telephone"></i>
//                   <h3>Call Us</h3>
//                   <p>+1 656-217-1792</p>
//                 </div>
//               </div>
//             </div>

//             <div className="row gy-4 mt-1">
//               <div className="col-lg-6">
//                 {/* Google Maps Embed */}
//                 <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d41818930.35112194!2d-123.23237808038162!3d50.22796560381668!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88c295ba866ddaf1%3A0xe737c74580151604!2sOdessa%2C%20FL%2C%20USA!5e0!3m2!1sen!2sin!4v1714462530743!5m2!1sen!2sin" width="100%" height="384" style={{ border: '0' }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
//               </div>

//               <div className="col-lg-6">
//                 {/* Contact Form */}
//                 <form id="contactForm" onSubmit={sendEmail} className="php-email-form">
//                   <div className="row gy-4">
//                     <div className="col-lg-6 form-group">
//                       <input type="text" name="name" className="form-control" id="name" placeholder="Your Name" value={formData.name} onChange={handleChange} required />
//                     </div>
//                     <div className="col-lg-6 form-group">
//                       <input type="email" className="form-control" name="email" id="email" placeholder="Your Email" value={formData.email} onChange={handleChange} required />
//                     </div>
//                   </div>
//                   <div className="form-group">
//                     <input type="text" className="form-control" name="subject" id="subject" placeholder="Subject" value={formData.subject} onChange={handleChange} required />
//                   </div>
//                   <div className="form-group">
//                     <textarea className="form-control" name="message" rows="5" placeholder="Message" value={formData.message} onChange={handleChange} required></textarea>
//                   </div>
//                   <div className="form-group">
//                     <label htmlFor="resume" className="form-label">Upload your Resume (max 5MB)</label>
//                     <input className="form-control form-control-lg" id="resume" name="resume" type="file" onChange={handleFileChange} />
//                     {error && <small className="text-danger">{error}</small>}
//                   </div>
                  
//                   <div className="text-center">
//                     <button type="submit" disabled={loading}>Send Message</button>
//                   </div>
//                 </form>
//               </div>
//             </div>
//           </div>
//         </section>
//       </main>
//       {/* Success Popup */}
//       {success && (
//         <div id="successPopup" className="success-container">
//           <div className="success-message">
//             <div className="msg-body">
//               <div className="main-msg">
//                 <div className="msg-border">
//                   <div className="msg-tick">
//                     <span style={{ fontSize: '71px', color: '#87cc6d' }}>&#10004;</span>
//                   </div>
//                 </div>
//               </div>
//               <div className="successful-msg">Successfully Submitted</div>
//               <div className="msg-copy">
//                 <div className="msg-link">We will get back to you soon!</div>
//               </div>
//               <div className="main_button">
//                 <button className="button_ok" onClick={closeSuccessPopup}>OK</button>
//               </div>
//             </div>
//           </div>
//         </div>
//       )}
//       <Footer />
//     </>
//   );
// }

// export default Contact;



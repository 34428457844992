import React from 'react';
import Footer from './footer';
import { Link } from 'react-router-dom';


function Mining() {
  return (
    <>
    <main id="main">
    <div className="breadcrumbs d-flex align-items-center" style={{backgroundImage: "url('assets/img/breadcrumbs-bg.jpg')"}}>
      <div className="container position-relative d-flex flex-column align-items-center" data-aos="fade">

        <h2>Data Mining</h2>
        <ol>
          <li><Link to="/">Home</Link></li>
           <li>Data Mining</li>
        </ol>

      </div>
    </div>

    <section id="about" className="about">
      <div className="container" data-aos="fade-up">

        <div className="row position-relative">


          <div className="col-lg-6">
            <h3>Data Mining</h3>
            <div className="our-story p-4">

              <p className="font-sz">Data mining plays a crucial role in extracting actionable knowledge from data and empowering organizations to make informed decisions, optimize processes, and gain a competitive advantage in today's data-driven world. </p>
                
                <p className="font-sz">
                Data mining is the process of discovering patterns, trends, and insights from large datasets using various techniques from statistics, machine learning, and database systems. The goal of data mining is to extract useful information and knowledge from data, which can then be used to make informed business decisions, predict future trends, or solve complex problems.
                </p>
               
                <p className="font-sz">
                Data mining has numerous applications across various industries such as marketing, finance, healthcare, retail, telecommunications, and cybersecurity, and plays a crucial role in extracting valuable knowledge from large datasets, enabling organizations to gain a competitive edge, optimize processes, and drive innovation
                </p>
                



            </div>
          </div>

            
            <div className="col-lg-6">
            <img src="assets/img/datamining.jpg" style={{width:"100%"}} />
            
            
          </div>

            
            
            
        </div>

      </div>
    </section>

    <section id="stats-counter" className="stats-counter section-bg">
      <div className="container">

        <div className="row gy-4">

          <div className="col-lg-12 col-md-12">
              
              <p>
              
             Data mining plays a crucial role in extracting actionable knowledge from data and empowering organizations to make informed decisions, optimize processes, and gain a competitive advantage in today's data-driven world.
              </p>
          
          </div>

         

      

         
        </div>

      </div>
    </section>

    </main>

    <Footer/>
    </>
  )
}

export default Mining;

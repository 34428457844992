import React from 'react'
import Footer from './footer';
import { Link } from 'react-router-dom';

function Lifesciences() {
  return (
  <>
  <main id="main">
  <div className="breadcrumbs d-flex align-items-center" style={{backgroundImage: "url('assets/img/breadcrumbs-bg.jpg')"}}>
      <div className="container position-relative d-flex flex-column align-items-center" data-aos="fade">

        <h2>Life Sciences</h2>
        <ol>
          <li><Link to="/">Home</Link></li>
           <li>Life Sciences</li>
        </ol>

      </div>
    </div>

    <section id="about" className="about">
      <div className="container" data-aos="fade-up">

        <div className="row position-relative">


          <div className="col-lg-6">
            <h3>Life Sciences</h3>
            <div className="our-story p-4">

              <p className="font-sz">Life sciences industry plays a critical role in advancing healthcare, improving patient outcomes, and driving economic growth and innovation. By harnessing scientific advances, embracing innovation, and collaborating across sectors, life sciences companies can address global health challenges, develop breakthrough therapies, and enhance the quality of life for individuals and communities around the world</p>
                
                
                <p className="font-sz">
                Life sciences encompass a diverse array of disciplines that focus on the study of living organisms, their structure, function, behavior, interactions, and ecosystems. It encompass a vast and interdisciplinary field of study that spans fundamental research, applied sciences, and practical applications in diverse sectors. Also, contribute to advancements in medicine, agriculture, biotechnology, environmental conservation, and scientific discovery, shaping our understanding of life and the natural world and driving innovation and progress in society.
                </p>
               
                <p className="font-sz">
                The convergence of information technology (IT) with the field of life sciences to enhance research, development, and applications in areas such as healthcare, pharmaceuticals, biotechnology, agriculture, and environmental sciences. IT solutions, software platforms, data analytics, and digital technologies are increasingly integrated into life sciences disciplines to accelerate scientific discovery, improve patient care, optimize drug development processes, enhance agricultural productivity, and address global challenges in healthcare and environmental sustainability
                </p>
                
           



            </div>
          </div>

            
            <div class="col-lg-6">
            <img src="assets/img/Sciences.png" style={{width:"100%"}} />
            
            
          </div>

            
            
            
        </div>

      </div>
    </section>

  </main>
  <Footer/>
  </>
  )
}

export default Lifesciences;
